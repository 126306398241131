import { graphql } from "gatsby"
import { getSrc, GatsbyImage } from "gatsby-plugin-image"
import React from "react"
import styled from "styled-components"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"
import { renderRichText } from "gatsby-source-contentful/rich-text"

import { contentfulRenderOptions } from "../utils"
import Layout from "../components/layout"
import Seo from "../components/seo"
import { getBlogPostLink, urls } from "../urls"
import { media } from "../styles/theme"

const S = {}

S.PostDate = styled.div`
  margin-bottom: 1rem;
  font-family: ${props => props.theme.fonts.body};
  font-size: 14px;
  text-transform: uppercase;
  font-style: italic;
  font-weight: 300;
`

S.PostContainer = styled.div`
  width: 100%;
  margin: 0 auto;

  ${media.desktop`
    width: 78%;
  `}

  ${media.tablet`
    p {
      text-align: justify;
    }
  `}
`

S.Author = styled.div`
  width: 360px;
  padding: 1rem 0;

  h3 {
    font-family: ${props => props.theme.fonts.body};
    font-size: 14px;
    text-transform: uppercase;
    font-style: italic;
    font-weight: 300;
    margin: 0;
  }

  p {
    margin: 0;
  }
`

const blogTemplate = ({ data, context }) => {
  const blogPost = data.contentfulBlogPost
  const author = blogPost.author

  const baseUrl = process.env.SITE_URL

  const jsonLdWebsite = {
    "@context": "http://schema.org",
    "@type": "WebSite",
    url: new URL(urls.BLOG, baseUrl).href,
    name: "Blog",
  }

  const jsonLdBreadcrumbs = {
    "@context": "https://schema.org",
    "@type": "BreadcrumbList",
    itemListElement: [
      {
        "@type": "ListItem",
        position: 1,
        item: process.env.SITE_URL,
        name: "Kamiko",
      },
      {
        "@type": "ListItem",
        position: 2,
        item: new URL(urls.BLOG, baseUrl).href,
        name: "Blog",
      },
      {
        "@type": "ListItem",
        position: 3,
        item: new URL(getBlogPostLink(blogPost.url), baseUrl).href,
        name: blogPost.title,
      },
    ],
  }

  const seoImageSrc = getSrc(blogPost.heroImage)

  const jsonLdArticle = {
    "@context": "http://schema.org",
    "@type": "BlogPosting",
    mainEntityOfPage: {
      "@type": "WebPage",
      "@id": new URL(getBlogPostLink(blogPost.url), baseUrl).href,
    },
    headline: blogPost.title,
    image: new URL(seoImageSrc, baseUrl).href,
    datePublished: new Date(blogPost.publicationDate).toISOString(),
    author: {
      "@type": "Person",
      name: author.name,
      url: author.url,
    },
  }

  let renderedIntro = null
  try {
    renderedIntro = documentToReactComponents(
      JSON.parse(blogPost.intro.raw),
      contentfulRenderOptions
    )
  } catch (e) {}

  let renderedBody = null
  try {
    renderedBody = renderRichText(blogPost.body, contentfulRenderOptions)
  } catch (e) {}

  return (
    <Layout>
      <Seo
        description={blogPost.seoDescription.seoDescription}
        imageSrc={seoImageSrc}
        jsonLdData={[jsonLdWebsite, jsonLdBreadcrumbs, jsonLdArticle]}
        title={blogPost.title}
        url={getBlogPostLink(blogPost.url)}
      />
      <S.PostContainer>
        <h1>{blogPost.title}</h1>
        <S.PostDate>
          {new Date(blogPost.publicationDate).toLocaleDateString("pl-PL")}
        </S.PostDate>
        {renderedIntro && (
          <div style={{ fontWeight: "500" }}>{renderedIntro}</div>
        )}
        {renderedBody && <div>{renderedBody}</div>}
        <S.Author
          style={{ display: "flex", alignItems: "center", gap: "1rem" }}
        >
          <div>
            <a href={author.url} target="_blank" rel="noopener noreferrer">
              <GatsbyImage
                style={{ borderRadius: "9999px" }}
                image={author.avatar.gatsbyImage}
              />
            </a>
          </div>
          <div>
            <h3>{author.name}</h3>
            {author.bio && (
              <div>
                {documentToReactComponents(
                  JSON.parse(author.bio.raw),
                  contentfulRenderOptions
                )}
              </div>
            )}
          </div>
        </S.Author>
      </S.PostContainer>
    </Layout>
  )
}

export const query = graphql`
  query($url: String!) {
    contentfulBlogPost(url: { eq: $url }) {
      title
      url
      createdAt
      publicationDate
      seoDescription {
        seoDescription
      }
      intro {
        raw
      }
      heroImage {
        gatsbyImage(
          formats: [PNG]
          placeholder: BLURRED
          width: 1200
          height: 630
          layout: FIXED
        )
      }
      body {
        raw
        references {
          ... on ContentfulEmbedVideo {
            __typename
            contentful_id
            name
            iframe {
              iframe
            }
          }
          ... on ContentfulAsset {
            __typename
            contentful_id
            description
            gatsbyImageData(
              layout: CONSTRAINED
              width: 1386
              placeholder: BLURRED
            )
          }
        }
      }
      author {
        name
        avatar {
          gatsbyImage(layout: CONSTRAINED, width: 70, placeholder: BLURRED)
        }
        bio {
          raw
        }
        url
      }
    }
  }
`
export default blogTemplate
